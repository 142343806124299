import React from "react";
import "./product-modal.styles.scss";
import { createStructuredSelector } from "reselect";
import { connect } from "react-redux";
import { selectProductForModal } from "../../redux/product-modal/product-modal.selector";
import { toggleModalHidden } from "../../redux/product-modal/product-modal.actions";
import { addItem } from "../../redux/cart/cart.actions";
import { AddedToCartModal as addedToCartModal } from "../../js/animations";
import AudioPlayer, { RHAP_UI } from "react-h5-audio-player";
import 'react-h5-audio-player/src/styles.scss'
const ProductModal = ({ product, toggleModalHidden, addItemToCart }) => {
  console.log(product);
  return (
    <div
      className="product-modal__container"
      onClick={() => toggleModalHidden(true)}
    >
      <div
        className="product-modal__modal"
        onClick={(e) => e.stopPropagation()}
      >
        <button
          className="product-modal__close-btn"
          onClick={(e) => {
            e.stopPropagation();
            toggleModalHidden(true);
          }}
        >
          ×
        </button>
        <img
          className="product-modal__img"
          src={product.imageUrl}
          alt="product"
        />
        <div className="product-modal__details">
          <p className="product-modal__product-name">{`${
            product.name
          }`}</p>  // Fixed closing tag
          <AudioPlayer
            autoPlay
            src={product.url}
            onPlay={(e) => console.log("onPlay")}
            // other props here
          />
        </div>
        <div className="product-modal__actions">
          <p className="product-modal__price"> UGX {product.price}</p>
          <button
            className="add-to-cart"
            onClick={(e) => {
              e.stopPropagation();
              toggleModalHidden();
              addItemToCart(product);
              addedToCartModal();
            }}
            style={{ 
              backgroundColor: '#4CAF50', // Change button color
              color: 'white', // Change text color
              border: 'none', // Remove border
              padding: '10px 20px', // Add padding
              textAlign: 'center', // Center text
              textDecoration: 'none', // Remove underline
              display: 'inline-block', // Inline block
              fontSize: '16px', // Increase font size
              margin: '4px 2px', // Add margin
              cursor: 'pointer', // Pointer cursor
              borderRadius: '5px', // Rounded corners
              transition: 'background-color 0.3s' // Smooth transition
            }}
            onMouseOver={(e) => e.currentTarget.style.backgroundColor = '#45a049'} // Hover effect
            onMouseOut={(e) => e.currentTarget.style.backgroundColor = '#4CAF50'} // Reset on mouse out
          >
            Add to Cart
          </button>
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = createStructuredSelector({
  product: selectProductForModal,
});

const mapDispatchToProps = (dispatch) => ({
  toggleModalHidden: () => dispatch(toggleModalHidden()),
  addItemToCart: (item) => dispatch(addItem(item)),
});
export default connect(mapStateToProps, mapDispatchToProps)(ProductModal);
