import React from "react";
import "./sign-up.styles.scss";

import FormInput from "../../components/form-input/form-input.component";
import CustomButton from "../../components/custom-button/custom-button.component";
import googleLogo from "../../assets/svg/google.svg";
import six from "../../assets/img/yono.jpg";

import { logo } from "../../assets/assets";

import { Link } from "react-router-dom";

class SignUp extends React.Component {
  render() {
    return (
      <div className="signup">
        <div className="left">
          <Link to="/#">
            {" "}
            <img src={logo} alt="logo" className="header__logo" />
          </Link>
          <form className="group" 
                   action="https://formspree.io/f/mzbwjyap"
                   method="POST"
                 >
            <h2>Billing Details</h2>
            <FormInput
            id="full-name"
              type="text"
              placeholder="Full Name"
              name="displayName"
            />
            <FormInput
              type="email"
              placeholder="Email"
               name="_replyto" id="email-address" 
            />
            <FormInput
              type="text"

              placeholder="Whatsapp Number"
               id="message"
               required=""
               name="message"
            />
           
        
            <CustomButton    type="submit"  href='/checkout'>Continue</CustomButton>
       
          </form>
      
        </div>

        <div
          className="right"
          style={{
            backgroundImage: `url(${six})`,
            backgroundSize: "cover",
            width: "46vw",
          }}
        />
      </div>
    );
  }
}

export default SignUp;
