const countdownDates = [
    {
        "name": "Beats & Bottles ",
        "startDate": "2024-11-02T15:00:15.000Z",
        "endDate": "2024-11-03T00:00:00.000+09:00"
    },
    // {
    //     "name": "Girl Power",
    //     "startDate": "2022-10-21T15:00:04.000Z",
    //     "endDate": "2022-11-19T18:00:00.000"
    // },
   
    // {
    //     "name": "Muna Kasese Show Up",
    //     "startDate": "2022-10-21T00:00:00",
    //     "endDate": "2022-11-26T00:00:00",
    // },
    // {
    //     "name": "Rwenzori Joint Prom Party",
    //     "startDate": "2022-10-21T15:00:04.000Z",
    //     "endDate": "2022-12-11T18:00:00.000"
    // },   
]

export default countdownDates;