import React, { useEffect } from "react";
import "./homepage.scss";
import Hero from "../components/hero/Hero";
import {SliderData} from "../components/hero/SliderData";
import SlickSlider from "../components/slider/slider.component"
import FormInput from "../components/form-input/form-input.component";
import { lydia, hero2, imgEthos1, imgEthos2 } from "../assets/assets";
import CollectionPreview from "../components/collections-preview/collections-preview.component";

import { ScrollTriggerAnimations } from "../js/animations";

import { VideoIsh } from "../assets/assets";
import { PlaySVG } from "../assets/assets";
import { connect } from "react-redux";
import { toggleModalHidden } from "../redux/product-modal/product-modal.actions";
import Events from "../components/Events/App";
import Hero2 from "../components/hero/hero.component";
import HomeMusic from "./shop/HomeMusic";
import Artists from "./Artists";
const HomePage = ({ setModalHidden }) => {
  useEffect(() => {
    ScrollTriggerAnimations();
    setModalHidden(true);
  }, []); // Added dependency array to avoid infinite loop

  return (
    <React.Fragment>
      <Hero2 />

      <section className="section__about anim-content">
        <div className="about__content">
          <div className="about__content--text">
            <h2 className="title__sub">About Lydia</h2> {/* Added title for clarity */}
            <p className="paragraph">
              Am Lydia Ugochi, an Artist, Vocalist, Mugisu from Mbale currently doing my music career from Kasese, Western Uganda. I was brought up by Nigerians giving me the name of Ugochi meaning God's Pride. I started my music when I was Young sing in Church choir and that's when I learnt much of vocals and music. I am a University Graduate from Makerere University having a Degree in Education in Literature.
              I was spotted out by one of the Big Brands that's Jackpower and decided to introduce me to different Artists in the West starting with Goodhope and that's when my music Journey started.
            </p>
          </div>

          <div className="about__content--img img">
            <img src={lydia} alt="Lydia Ugochi" className="img-1" />
            <div className="img-2__banner">
              <img src={hero2} alt="Hero Banner" className="img-2" />
            </div>
          </div>
        </div>
      </section>

      <h1 style={{ textAlign: "center", fontSize: "25px", margin: "20px 0" }}>LATEST <span style={{ color: "darkolivegreen", fontWeight: "900" }}>PROJECT</span></h1>
      <Artists />
      <div>
        <Events />
        <h1 style={{ textAlign: "center", fontSize: "25px", marginTop: "25px" }}>DA <span style={{ color: "darkolivegreen", fontWeight: "900" }}>LIONESS</span></h1>
        <SlickSlider />
      </div>
    </React.Fragment>
  );
};

const mapDispatchToProps = (dispatch) => ({
  setModalHidden: (value) => dispatch(toggleModalHidden(value)),
});
export default connect(null, mapDispatchToProps)(HomePage);
