import React, { Component } from "react";
import style from "./Artists.module.css";

class Artists extends Component {
  render() {
    return (
      <>
        <div className="container-fluid">
          <div className={`${style.box} col-xs-10 col-md-4`}>
            <a href="/music" className={style.artistLink}>
              <img 
                src="https://res.cloudinary.com/dejrg86ln/image/upload/v1730075013/photo_2024-10-28_11-20-38_dxwxhv.jpg" 
                alt="Lydia Ugochi" 
                className={`${style.Artists} ${style.hoverEffect}`} 
              />
              <div className="dbtn"></div>           
            </a>
          </div>
        </div>
      </>
    );
  }
}
export default Artists;
