import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import TextAnimation from "./TextAnimation";

const HeroContainer = styled.div`
  max-height: 150vh;
  width: 100vw;
  overflow-x: unset;
  background-color: #0d1117;
  background-image: url("https://res.cloudinary.com/dejrg86ln/image/upload/v1730073050/herougochi_oxmbwe.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  position: relative;

  .hero__container {
    position: absolute;
    width: 100%;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 3;
    text-align: center;

    h1 {
      font-size: 3rem;
      font-weight: bolder;
      color: white;
    }

    h2 {
      font-size: 1.6vw;
      color: #f2f2f2;
    }
  }

  .hero__cta {
    display: inline-block;
    background-color: darkolivegreen;
    color: white;
    padding: 1.4vw 3vw;
    font-weight: bold;
    font-size: 1.2rem; /* Adjust this based on your design needs */
    margin-top: 2.6rem;
  }

  .hero__slider {
    padding-top: 75vh;
  }

  @media (max-width: 600px) {
    max-height: 100vh;
    min-height: 100vh;

    .hero__container h2 {
      font-size: 5vw;
      padding: 1vw 10vw;
      font-weight: 100;
    }

    .hero__cta {
      padding: 2.6vw 6vw;
      font-size: 1rem; /* Adjust based on design */
      font-weight: 200;
    }

    .hero__slider {
      padding-top: 74vh;
    }
  }

  @media (max-width: 1024px) {
    .hero__slider {
      padding-top: 34vw;
    }
  }
`;

const Hero2 = () => {
  return (
    <HeroContainer>
      <div className="hero">
        <div className="hero__container">
          <h1>I'm Lydia Ugochi</h1>
          <h2>
            <TextAnimation />
          </h2>
          <Link to="/Music" className="hero__cta">
            Check out my Music
          </Link>
        </div>

        <div className="hero__slider"></div>
      </div>
    </HeroContainer>
  );
};

export default Hero2;
