import React, { useEffect } from "react";
import "./App.css";
import "./scss/main.scss";
import { Switch, Route, withRouter, Redirect } from "react-router-dom";
import Header from "./components/header/header.component";
import Homepage from "./pages/homepage";
import LogIn from "./pages/Log-in/log-in.component";
import SignUp from "./pages/sign-up/sign-up.component";
import Everything from "./pages/shop/everything.component";
import Checkout from "./pages/checkout/checkout.component";
import Footer from "./components/Footer/Footer";
import { connect } from "react-redux";
import { createStructuredSelector } from "reselect";

import { setCurrentUser } from "./redux/user/user.actions";
import { selectCurrentUser } from "./redux/user/user.selectors";
import { selectCollectionsForPreview } from "./redux/shop/shop.selectors";
import createHistory from "history/createBrowserHistory";
import ProductModal from "./components/product-modal/product-modal.component";
import SHOP_DATA from "./pages/shop/shop.data";
import { selectModalHidden } from "./redux/product-modal/product-modal.selector";
import Contact from "./components/Bookings/contact";
import ContactPage from "./components/Bookings/ContactPage";
import Order from "./pages/song";

export const history = createHistory();

history.listen((location, action) => {
  window.scrollTo(0, 0);
});

class App extends React.Component {

  
  unsubscribefromAuth = null;

  componentDidMount() {
    const { setCurrentUser, collectionsArray } = this.props;

    // Remove Firebase authentication logic
    // this.unsubscribefromAuth = auth.onAuthStateChanged(async (userAuth) => {
    //   if (userAuth) {
    //     const userRef = await createUserProfileDocument(userAuth);

    //     userRef.onSnapshot((snapShot) => {
    //       setCurrentUser({
    //         currentUser: {
    //           id: snapShot.id,
    //           ...snapShot.data(),
    //         },
    //       });
    //     });
    //   } else {
    //     setCurrentUser(userAuth);
    //     addCollectionAndDocuments("collections", collectionsArray);
    //   }
    // });
  }

  componentWillUnmount() {
    // this.unsubscribefromAuth(); // Remove this line
  }
  

  render() {
    return (
      <div
        style={{
          position: "relative",
        }}
      >
        {this.props.location.pathname !== "/login" &&
        this.props.location.pathname !== "/signup" ? (
          <Header />
          
        ) : null}
        {!this.props.productModalHidden ? (
          <Route
            path={["/category", "/music"]}
            component={ProductModal}
          />
        ) : null}
        <Switch>
          <Route exact path="/" component={Homepage} />
          <Route exact path="/file/d/1RYvimvim5wPmv76yIimKrBhAHxn5q9NSFytyrfdsafgfcxzcx/view" component={Order} />

          <Route
            path="/login"
            render={() =>
              this.props.currentUser ? <Redirect to="/" /> : <LogIn />
            }
          />
          <Route
            path="/signup"
            render={() =>
              this.props.currentUser ? <Redirect to="/" /> : <SignUp />
            }
          />
          <Route exact path="/music" component={Everything} />
          <Route exact path="/bookings" component={ContactPage} />
          <Route exact path="/checkout" component={Checkout} />
          <Route path='/donate' component={() => { 
     window.location.href = 'https://flutterwave.com/donate/aeqvbrlugmzk'; 
     return null;
}}/>

        </Switch>
        {this.props.location.pathname !== "/login" &&
        this.props.location.pathname !== "/signup" ? (
          <Footer />
        ) : null}{" "}
      </div>
    );
  }
}

const matchStateToProps = createStructuredSelector({
  currentUser: selectCurrentUser,
  collectionsArray: selectCollectionsForPreview,
  productModalHidden: selectModalHidden,
});
const matchDispatchToProps = (dispatch) => ({
  setCurrentUser: (user) => dispatch(setCurrentUser(user)),
});

export default connect(
  matchStateToProps,
  matchDispatchToProps
)(withRouter(App));
