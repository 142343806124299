import { saveAs } from "file-saver";
import React, { useState } from 'react';
import { Button } from "./Button";
import './Audiofile.css'

const SONG_FILE_URL = 'https://lydiaugochi.com/Worrior_lydia_ugochi.mp3'


function Audio() {

const downloadFileAtURL = (url) => {
  saveAs(url); // Use saveAs from file-saver
}

  return (
    <div className="audio">
      <Button onClick={()=>{downloadFileAtURL(SONG_FILE_URL)}}>Download Now</Button>
    </div>
  );
}

export default Audio;
